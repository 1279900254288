import React from 'react';
import { Label } from 'components/label/Label.component';
import { List } from 'components/list/List.component';
import { RadioGroup } from 'components/radioGroup/RadioGroup.component';
import { ListItemElement } from 'components/listItemElement/ListItemElement.component';
import { ChipPlaceholder } from 'components/chip/ChipPlaceholder.component';
import { TextAmount } from 'components/textAmount/TextAmount';
export function Drive(_a) {
    var drives = _a.drives, selectedDrive = _a.selectedDrive, onSelectedDriveChange = _a.onSelectedDriveChange, handleOnTokenChange = _a.handleOnTokenChange, isReadOnly = _a.isReadOnly;
    function onDriveChange(value) {
        var token = drives.find(function (drive) { return value === drive.powerTrainId; }).token;
        handleOnTokenChange(token);
        onSelectedDriveChange(value, token);
    }
    var selectedDriveObject = drives.find(function (drive) { return drive.powerTrainId === selectedDrive; });
    return (React.createElement("div", { className: "driveWrapper" }, drives ? (isReadOnly ?
        React.createElement("div", null,
            React.createElement(List.Root, null,
                React.createElement(List.Item, { isSelected: true, cursor: 'default' },
                    React.createElement(List.LayoutWithCodeAndChips, null,
                        React.createElement(RadioGroup.Layout, null,
                            React.createElement(RadioGroup.Placeholder, null),
                            React.createElement(Label, { htmlFor: selectedDriveObject.powerTrainId }, selectedDriveObject.displayName)),
                        React.createElement(ListItemElement.OptionCodePlaceholder, null),
                        React.createElement(ListItemElement.Chips, null,
                            React.createElement(ChipPlaceholder, null)),
                        React.createElement(ListItemElement.Price, null,
                            React.createElement(TextAmount, { bold: true }, selectedDriveObject.powerTrainPrice))))))
        : React.createElement(RadioGroup.Root, { value: selectedDrive, onValueChange: onDriveChange },
            React.createElement(List.Root, null, drives.map(function (drive) { return (React.createElement(List.Item, { key: drive.powerTrainId, isSelected: selectedDrive === drive.powerTrainId, onClick: function (event) {
                    if (['INPUT', 'SPAN', 'BUTTON'].includes(event.target.tagName)) {
                        return;
                    }
                    onDriveChange(drive.powerTrainId);
                } },
                React.createElement(List.LayoutWithCodeAndChips, null,
                    React.createElement(RadioGroup.Layout, null,
                        React.createElement(RadioGroup.Item, { value: drive.powerTrainId, id: drive.powerTrainId },
                            React.createElement(RadioGroup.Indicator, null)),
                        React.createElement(Label, { htmlFor: drive.powerTrainId }, drive.displayName)),
                    React.createElement(ListItemElement.OptionCodePlaceholder, null),
                    React.createElement(ListItemElement.Chips, null,
                        React.createElement(ChipPlaceholder, null),
                        React.createElement(ChipPlaceholder, null)),
                    React.createElement(ListItemElement.Price, null,
                        React.createElement(TextAmount, { bold: true }, drive.powerTrainPrice))))); })))) : null));
}
